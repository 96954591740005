import React, { useState, useEffect } from "react";
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  getOrderDetails,
  payOrder,
  //   deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  //   ORDER_DELIVER_RESET,
} from "../constants/orderConstants";

function OrderScreen({ match, history }) {
  const orderId = match.params.id;
  const dispatch = useDispatch();

  const [sdkReady, setSdkReady] = useState(false);

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  //   const orderDeliver = useSelector((state) => state.orderDeliver);
  //   const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  //   const userLogin = useSelector((state) => state.userLogin);
  //   const { userInfo } = userLogin;

  if (!loading && !error) {
    order.itemsPrice = order.orderItems
      .reduce((acc, item) => acc + item.price * item.qty, 0)
      .toFixed(2);
  }

  const addPayPalScript = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AWQ-bSA7fI5iDFSoY4OsZc2hi9kr0aOl9lJyZLVctIPUJKpIvfCACXbpnaUVO3r84U0JWbBoZojf_tpm";
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    // if (!userInfo) {
    //   history.push("/login");
    // }

    if (
      !order ||
      successPay ||
      order._id !== Number(orderId)
      //   ||
      //   successDeliver
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      //   dispatch({ type: ORDER_DELIVER_RESET });

      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [dispatch, order, orderId, successPay]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };

  //   const deliverHandler = () => {
  //     dispatch(deliverOrder(order));
  //   };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div>
      <div>
        <img class="bg" src="https://i.imgur.com/0vjgoXd.jpeg" />
        {/* <p> Please make sure your paypal address is correct</p> */}
        <Col md={4}>
          <Card className="fullprice">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2 className="summary">Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row className="summary">
                  <Col className="sum">Items:${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row className="summary">
                  <Col className="sum">Shipping:${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row className="summary">
                  <Col className="sum">Tax:${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row className="summary">
                  <strong className="total">Total:${order.totalPrice}</strong>
                </Row>
              </ListGroup.Item>

              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}

                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )}
            </ListGroup>
            {/* {loadingDeliver && <Loader />}
            {userInfo &&
              userInfo.isAdmin &&
              order.isPaid &&
              !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn btn-block"
                    // onClick={deliverHandler}
                  >
                    Mark As Delivered
                  </Button>
                </ListGroup.Item> */}
            {/* )} */}
          </Card>
        </Col>

        <Row>
          <Col md={8}>
            <br></br>
            <br></br>

            <h1 className="shipping">Order: {order.Id}</h1>
            <p className="shp">
              {" "}
              Please make sure your paypal shipping address is accurate
            </p>
            <br></br>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p className="shp">
                  <strong className="shp">Shipping: </strong>
                  {order.shippingAddress.address}, {order.shippingAddress.city}
                  {"  "}
                  {order.shippingAddress.postalCode},{"  "}
                  {order.shippingAddress.country}
                </p>
              </ListGroup.Item>

              <ListGroup.Item className="paid">
                <h2 className="shp">Payment Method</h2>
                <p className="shp">
                  <strong className="shp">Method: </strong>
                  {order.paymentMethod}
                </p>
                <br></br>
                <Row className="paid">
                  {order.isPaid ? (
                    <Message className="shp" variant="success">
                      Paid on {order.paidAt}
                    </Message>
                  ) : (
                    <Message className="shp" variant="warning">
                      Not Paid
                    </Message>
                  )}
                </Row>
              </ListGroup.Item>

              <ListGroup.Item className="shp">
                <h2>Order Items</h2>
                {order.orderItems.length === 0 ? (
                  <Message variant="info">Order is empty</Message>
                ) : (
                  <ListGroup variant="flush">
                    {order.orderItems.map((item, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col md={1}>
                            <Image
                              className="shipimg"
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Col>

                          <Col className="itmname">
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </Col>

                          <Col className="itemtxt">
                            {item.qty} X ${item.price} = $
                            {(item.qty * item.price).toFixed(2)}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default OrderScreen;
