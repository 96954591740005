import React from "react";
// import { Navbar } from "react-bootstrap";

function LandingScreen() {
  return (
    <body>
      <div>
        <img className="bg" src="https://i.imgur.com/0vjgoXd.jpeg" />
        <section className="link-container">
          <a className="word" href="#/home">
            Please Hate Me Inc.
          </a>
        </section>
      </div>
    </body>
  );
}
export default LandingScreen;
