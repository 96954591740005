import React from "react";
import { Container, Row, Col } from "react-bootstrap";
function footer() {
  return (
    <footer>
      <Container>
        <a className="ytlink" href="https://www.youtube.com/@pleasehatemeinc">
          <img class="yot" src="https://i.imgur.com/mxQpvMd.png" />
        </a>
        <a className="twtlink" href="https://twitter.com/plzhtemeinc">
          <img class="twt" src="https://i.imgur.com/TD1qcML.png" />
        </a>
        <a
          className="archlink"
          href="https://www.youtube.com/playlist?list=PLjvGw7Lob2XTXWk-vq5Ckvar6TDEkRqLA"
        >
          <img class="arch" src="https://i.imgur.com/KXQA6WJ.png" />
        </a>
      </Container>
    </footer>
  );
}

export default footer;
