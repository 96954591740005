import React from "react";
import { Navbar, Nav, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import SearchBox from "./SearchBox";
function header() {
  return (
    <header>
      <Container>
        <a className="homelink" href="#/home/">
          <img class="werm" src="https://i.imgur.com/WmDk72e.png" />
        </a>

        <a className="cartlink" href="#/cart/">
          <img class="cort" src="https://i.imgur.com/J5edWhT.png" />
        </a>
      </Container>
    </header>
  );
}

export default header;
