import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { listProducts } from "../actions/productActions";

function HomeScreen({ history }) {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { error, loading, products } = productList;

  let keyword = history.location.search;
  console.log(keyword);
  useEffect(() => {
    dispatch(listProducts(keyword));
  }, [dispatch, keyword]);

  return (
    <div>
      <img class="bg" src="https://i.imgur.com/0vjgoXd.jpeg" />

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div className="productbox">
          <Row>
            {products.map((product) => (
              <ul>
                <Col
                  className="pcol"
                  key={product.placement}
                  sm={12}
                  md={7}
                  lg={5}
                  xl={4}
                >
                  <Product product={product} />
                </Col>
              </ul>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

export default HomeScreen;
