import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Button,
  Card,
  Form,
  Container,
} from "react-bootstrap";
import Product from "../components/Product";
import Rating from "../components/Rating";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listProductsDetails } from "../actions/productActions";

function ProductScreen({ match, history }) {
  const [qty, setQty] = useState(1);

  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    dispatch(listProductsDetails(match.params.id));
  }, []);

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };
  const ytlinker = () => {
    window.open(product.yt);
  };
  const bclinker = () => {
    window.open(product.bc);
  };

  return (
    <div>
      <img class="bg" src="https://i.imgur.com/0vjgoXd.jpeg" />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Row>
          <Col md={3}>
            <ListGroup variant="flush">
              <ListGroup.Item className="itemname">
                <h3>{product.name}</h3>
              </ListGroup.Item>

              <ListGroup.Item className="artistname">
                {product.Artist}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Container>
            <Row>
              <Col className="productside1">
                <Image className="productimg1" src={product.image} />
              </Col>
              <Col className="extraimg">
                <Image className="productimg2" src={product.image2} />

                <Image className="productimg3" src={product.image3} />
                <Image className="productimg4" src={product.image4} />
              </Col>
            </Row>
          </Container>

          <Col className="descript">
            <Row xs={3} className="desc1">
              <strong>{product.description1}</strong>
            </Row>

            <Row className="desc2">{product.description2}</Row>
            <Row className="desc3">{product.description3}</Row>
            <ListGroup.Item>
              <Row className="price">
                {" "}
                {product.countInStock > 0 ? "In Stock" : ""}{" "}
                <Col>{product.price}</Col>
              </Row>
            </ListGroup.Item>
            <Col className="allbtn">
              <Button
                onClick={addToCartHandler}
                className="add"
                disabled={product.countInStock === 0}
                type="button"
              >
                <strong> Add To cart </strong>
              </Button>

              <Button className="btn1" onClick={ytlinker} type="button">
                <strong className="yt"> Listen </strong>
              </Button>
              <Button className="btn1" onClick={bclinker} type="button">
                <strong className="bc"> Download </strong>
              </Button>
            </Col>
          </Col>

          <Col md={3}>
            <Card>
              <ListGroup varient="flush">
                <Row className="stock">
                  <strong></strong>
                </Row>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ProductScreen;
