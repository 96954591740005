import { Container } from "react-bootstrap";
import { HashRouter as HashRouter, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LandingScreen from "./screens/LandingScreen";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";

function App() {
  return (
    <HashRouter>
      <Header />
      <Container>
        <Route path="//" component={LandingScreen} exact />
        <Route path="/home/" component={HomeScreen} exact />
        <Route path="/product/:id/" component={ProductScreen} />
        <Route path="/shipping/" component={ShippingScreen} />
        <Route path="/placeOrder/" component={PlaceOrderScreen} />
        <Route path="/order/:id/" component={OrderScreen} />
        <Route path="/payment/" component={PaymentScreen} />
        <Route path="/cart/:id?/" component={CartScreen} />
      </Container>
      <Footer />
    </HashRouter>
  );
}

export default App;
