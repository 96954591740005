import React, { useState, useEffect } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { saveShippingAddress } from "../actions/cartActions";

function ShippingScreen({ history }) {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const dispatch = useDispatch();

  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(shippingAddress.country);
  const [state, setState] = useState(shippingAddress.state);
  const [email, setEmail] = useState(shippingAddress.email);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ address, city, postalCode, country }));
    history.push("/payment");
  };

  return (
    <div>
      <div>
        <img class="bg" src="https://i.imgur.com/0vjgoXd.jpeg" />
        <FormContainer>
          <CheckoutSteps step1 step2 />
          <h1 className="shipping">Shipping</h1>
          <Col className="formg" align="center">
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="email">
                <Form.Label className="label">Email</Form.Label>
                <Form.Control
                  className="formbox"
                  required
                  type="text"
                  placeholder="Enter Email"
                  value={email ? email : ""}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label className="label">Address</Form.Label>
                <Form.Control
                  className="formbox"
                  required
                  type="text"
                  placeholder="Enter address"
                  value={address ? address : ""}
                  onChange={(e) => setAddress(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label className="label">City</Form.Label>
                <Form.Control
                  className="formbox"
                  required
                  type="text"
                  placeholder="Enter city"
                  value={city ? city : ""}
                  onChange={(e) => setCity(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  className="formbox"
                  required
                  type="text"
                  placeholder="Enter State"
                  value={state ? state : ""}
                  onChange={(e) => setState(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="postalCode">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  className="formbox"
                  required
                  type="text"
                  placeholder="Enter postal code"
                  value={postalCode ? postalCode : ""}
                  onChange={(e) => setPostalCode(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  className="formbox"
                  required
                  type="text"
                  placeholder="Enter country"
                  value={country ? country : ""}
                  onChange={(e) => setCountry(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type="submit" variant="primary" className="cont">
                <strong>Continue</strong>
              </Button>
            </Form>
          </Col>
        </FormContainer>
      </div>
    </div>
  );
}

export default ShippingScreen;
