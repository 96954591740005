import React, { useState, useEffect } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { savePaymentMethod } from "../actions/cartActions";

function PaymentScreen({ history }) {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const dispatch = useDispatch();

  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  if (!shippingAddress.address) {
    history.push("/shipping");
  }

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    history.push("/placeOrder");
  };

  return (
    <div>
      <div>
        <img class="bg" src="https://i.imgur.com/0vjgoXd.jpeg" />
        <Col className="sub">
          <FormContainer>
            <Form onSubmit={submitHandler}>
              <Form.Group>
                <Form.Label className="select" as="legend">
                  Select Payment Method
                </Form.Label>

                <Form.Check
                  className="check"
                  type="radio"
                  label="PayPal or Credit Card"
                  id="paypal"
                  name="paymentMethod"
                  checked
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
              </Form.Group>

              <Button type="submit" variant="primary" className="cont">
                Continue
              </Button>
            </Form>
          </FormContainer>
        </Col>
      </div>
    </div>
  );
}

export default PaymentScreen;
