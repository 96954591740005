import React, { useState, useEffect } from "react";
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";

function PlaceOrderScreen({ history }) {
  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, error, success } = orderCreate;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  cart.itemsPrice = cart.cartItems
    .reduce((acc, item) => acc + item.price * item.qty, 0)
    .toFixed(2);
  cart.shippingPrice = (cart.itemsPrice > 100 ? 0 : 10).toFixed(2);
  cart.taxPrice = Number(0.082 * cart.itemsPrice).toFixed(2);

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  if (!cart.paymentMethod) {
    history.push("/payment");
  }

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [success, history]);

  const placeOrder = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
    <div>
      <img class="bg" src="https://i.imgur.com/0vjgoXd.jpeg" />
      <CheckoutSteps step1 step2 step3 step4 />
      <Card className="fullprice">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <h2 className="summary">Order Summary</h2>
          </ListGroup.Item>

          <ListGroup.Item>
            <Row className="summary">
              <Col className="sum">Items:${cart.itemsPrice}</Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item>
            <Row className="summary">
              <Col className="sum">Shipping:${cart.shippingPrice}</Col>
            </Row>
          </ListGroup.Item>
          <br></br>

          {/* <ListGroup.Item>
                  <Row className="summary">
                    <Col className="sum">Tax:${cart.taxPrice}</Col>
                  </Row>
                </ListGroup.Item> */}

          <ListGroup.Item>
            <Row className="summary">
              <Col className="total">
                <strong>Total:</strong>

                <strong>${cart.totalPrice}</strong>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item>
            {error && <Message variant="danger">{error}</Message>}
          </ListGroup.Item>

          <ListGroup.Item>
            <Button
              type="button"
              className="btn-block"
              disabled={cart.cartItems === 0}
              onClick={placeOrder}
            >
              Place Order
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Card>

      <Row>
        <Col>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2 className="carthead">Summary</h2>

              <p className="shp">
                <strong>Address: </strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}
                {"  "}
                {cart.shippingAddress.postalCode},{"  "}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2 className="shp">Payment Method</h2>
              <p className="shp">
                <strong className="shp">Method: </strong>
                {cart.paymentMethod}
              </p>
            </ListGroup.Item>
            <Col className="up">
              {" "}
              <ListGroup.Item className="shp">
                {cart.cartItems.length === 0 ? (
                  <Message variant="info">Your cart is empty</Message>
                ) : (
                  <ListGroup variant="flush">
                    {cart.cartItems.map((item, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col md={1}>
                            <Image
                              className="shipimg"
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Col>

                          <Col className="itmname">
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </Col>

                          <Col className="itemtxt">
                            {item.qty} X ${item.price} = $
                            {(item.qty * item.price).toFixed(2)}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            </Col>
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
}

export default PlaceOrderScreen;
